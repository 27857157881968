import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import {
  useChat, useOffer, useTrade, useUser,
} from '../../../../../stores/hooks';
import { DisputeInterface, OfferInterface, UserInterface } from '../../../../../interfaces';
import { AuthToken } from '../../../../../services';
import { listMessagesForRoom, getRoom } from '../../../../../graphql/queries';
import { Message, ModelSortDirection, Room } from '../../../../../ChatAPI';
import { onCreateMessageByRoomId } from '../../../../../graphql/subscriptions';
import { createMessage } from '../../../../../graphql/mutations';
import { Avatar, Button, Loader } from '../../../../Atoms';
import { AdminPaths, ButtonCategory, DisputeStatusTypes } from '../../../../../constants';
import { OfferTradeDetails } from '../../../../Organisms';
import { AttachmentIcon } from '../../../../Atoms/icons';

const Dispute: React.FC = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const client = generateClient();

  const tokens = new AuthToken();
  const accessToken = tokens.getAccessToken();

  const [isLoading, setIsLoading] = useState(false);

  const [inputValue, setInputValue] = useState<string | File>('');

  const [currentUser, setCurrentUser] = useState<UserInterface>();
  const [buyer, setBuyer] = useState<UserInterface>();
  const [seller, setSeller] = useState<UserInterface>();

  const [activeTab, setActiveTab] = useState(0);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRefundLoading, setIsRefundLoading] = useState(false);

  const [dispute, setDispute] = useState<DisputeInterface>();
  const [offer, setOffer] = useState<OfferInterface>();

  const [rooms, setRooms] = useState<Room[]>();
  const [currentRoom, setCurrentRoom] = useState<Room>();

  const [messages, setMessages] = useState<Message[]>([]);

  const { getOfferById } = useOffer();
  const { getUserById } = useUser();
  const { getTradeDispute, resolveDispute } = useTrade();
  const { createChatFile } = useChat();

  const { tradeId } = useParams();
  const navigate = useNavigate();

  const handleMessageSend = (newMessage: string, fileUrl?: string) => {
    const content = { text: newMessage, imageId: fileUrl };

    client.graphql({
      query: createMessage,
      authToken: accessToken,
      variables: {
        input: {
          content,
          roomId: currentRoom?.id || '',
          userId: dispute?.id || '',
        },
      },
    }).then(({ data }) => {
      console.log('message data message', data.createMessage);
      setMessages((msg) => [...msg, data.createMessage]);
    }).catch((e) => {
      console.log('error', e);
    });
  };

  const handleSendClick = () => {
    // const profileImageURL = URL.createObjectURL(file as File);
    if (inputValue instanceof File) {
      const formData = new FormData();
      formData.append('chatFile', inputValue);
      setIsLoading(true);
      createChatFile(formData).then((res) => {
        console.log('data', res.headers.location);
        handleMessageSend(inputValue.name, res.headers.location as string);
        setInputValue('');
        setIsLoading(false);
      }).catch((reason) => {
        console.log('reason', reason);
        setIsLoading(false);
      });
    } else {
      handleMessageSend(inputValue as string);
      setInputValue('');
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const currentFile = event.target.files[0];
      setInputValue(currentFile);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  useEffect(() => {
    if (tradeId) {
      getTradeDispute(tradeId).then((res) => {
        setDispute(res);
      });
    }
  }, [tradeId]);

  useEffect(() => {
    if (dispute) {
      Promise.all([
        getUserById(dispute.buyerUserId),
        getUserById(dispute.sellerUserId),
        getOfferById(dispute.offerId),
        client.graphql({
          query: getRoom,
          variables: {
            name: `${dispute.tradeId}+${dispute.buyerUserId}`,
          },
          authToken: accessToken,
        }),
        client.graphql({
          query: getRoom,
          variables: {
            name: `${dispute.tradeId}+${dispute.sellerUserId}`,
          },
          authToken: accessToken,
        }),
        client.graphql({
          query: getRoom,
          variables: {
            name: dispute.tradeId,
          },
          authToken: accessToken,
        })])
        .then((res) => {
          setBuyer(res[0]);
          setSeller(res[1]);
          setOffer(res[2]);

          const buyerRoom = res[3].data.getRoom;
          const sellerRoom = res[4].data.getRoom;
          const tradeRoom = res[5].data.getRoom;

          setRooms([buyerRoom, sellerRoom, tradeRoom] as Room[]);
        })
        .catch((e) => {
          console.log('message list error', e);
        });
    }
  }, [dispute]);

  useEffect(() => {
    const room = rooms?.find(({ name }) => name === `${dispute?.tradeId}+${dispute?.buyerUserId}`);
    if (room) {
      setCurrentRoom(room);
      setCurrentUser(buyer);
    }
  }, [rooms]);

  useEffect(() => {
    setMessages([] as Message[]);

    if (currentRoom) {
      client.graphql({
        query: listMessagesForRoom,
        authToken: accessToken,
        variables: {
          roomId: currentRoom?.id,
          sortDirection: ModelSortDirection.ASC,
        },
      })
        .then(({ data }) => {
          // if (endOfDivRef.current) {
          //   endOfDivRef.current.scrollTop = endOfDivRef.current.scrollHeight + 30;
          //
          //   // endOfDivRef.current.scrollIntoView({ behavior: 'smooth' });
          // }
          setMessages(data.listMessagesForRoom?.items as Message[]);
        });

      const subscription = client.graphql({
        query: onCreateMessageByRoomId,
        authToken: accessToken,
        variables: { roomId: (currentRoom as Room).id },
      })
        .subscribe({
          next: (value) => {
            if (value.data.onCreateMessageByRoomId.userId === dispute?.buyerUserId
                || value.data.onCreateMessageByRoomId.userId === dispute?.sellerUserId) {
              setMessages((msg) => [...msg, value.data.onCreateMessageByRoomId]);
            }
          },
        });

      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [currentRoom]);

  const handleUserTabClick = (user?: UserInterface) => {
    if (user) {
      const room = rooms?.find(({ name }) => name === `${tradeId}+${user?.id}`);
      if (room) {
        setCurrentRoom(room);
        setCurrentUser(user);
      }
    } else {
      const room = rooms?.find(({ name }) => name === dispute?.tradeId);
      if (room) {
        setCurrentRoom(room);
        setCurrentUser({} as UserInterface);
      }
    }
  };
  const handleResolveClick = (status: DisputeStatusTypes) => {
    if (dispute) {
      if (status === DisputeStatusTypes.Approved) {
        setIsApproveLoading(true);
      }
      if (status === DisputeStatusTypes.Rejected) {
        setIsRefundLoading(true);
      }
      resolveDispute({ status }, dispute?.tradeId).then(() => {
        if (status === DisputeStatusTypes.Approved) {
          setIsApproveLoading(false);
        }
        if (status === DisputeStatusTypes.Rejected) {
          setIsRefundLoading(false);
        }
        navigate(`${AdminPaths.Disputes}?status=Open`);
      });
    }
  };

  const users = [
    { user: buyer, subTitle: '(seller)' },
    { user: seller, subTitle: '(buyer)' },
  ];

  return (
    <div className="flex flex-col gap-10 h-[calc(100vh_-_200px)]">
      {dispute?.disputeStatus === DisputeStatusTypes.Open && (
        <div className="flex max-xsm:flex-col gap-4 justify-end w-full">
          <Button
            className="min-w-[180px] max-xsm:w-full"
            category={ButtonCategory.Filled}
            onClick={() => {
              handleResolveClick(DisputeStatusTypes.Approved);
            }}
            disabled={isApproveLoading || isRefundLoading}
            isLoading={isApproveLoading}
          >
            Release
          </Button>
          <Button
            className="min-w-[180px] max-xsm:w-full"
            category={ButtonCategory.Outlined}
            onClick={() => {
              handleResolveClick(DisputeStatusTypes.Rejected);
            }}
            disabled={isApproveLoading || isRefundLoading}
            isLoading={isRefundLoading}
          >
            Refund
          </Button>
        </div>
      )}
      <div className="flex gap-10 flex-wrap">
        <div className="max-w-3xl w-full">
          <div className="flex gap-2 [&>button]:rounded-b-none">
            {users.map(({ user, subTitle }, index) => (
              <button
                onClick={() => {
                  setActiveTab(index);
                  handleUserTabClick(user as UserInterface);
                }}
                className={` h-auto cursor-pointer sticky flex items-center justify-between px-6 py-3 dark:border-strokedark dark:hover:bg-strokedark ${activeTab === index ? 'dark:bg-strokedark' : 'dark:bg-boxdark'}`}
              >
                <div className="flex items-center gap-4 w-full">
                  <div className="flex items-center justify-center h-10 w-10 overflow-hidden rounded-full bg-blue text-primary">
                    <h5>{user?.userName?.substring(0, 1).toUpperCase()}</h5>
                  </div>
                  <div className="max-sm:hidden">
                    <h5 className="font-medium text-black dark:text-white">{user?.userName}</h5>
                    <p className="text-sm">{subTitle}</p>
                  </div>
                </div>
              </button>
            ))}
            <button
              onClick={() => {
                setActiveTab(2);
                handleUserTabClick();
              }}
              className={` h-auto cursor-pointer sticky flex items-center justify-between px-6 py-3 dark:border-strokedark dark:hover:bg-strokedark ${activeTab === 2 ? 'dark:bg-strokedark' : 'dark:bg-boxdark'}`}
            >
              <div className="flex flex-col items-center w-full">
                <h5 className="max-xsm:text-[14px]">Trade chat</h5>
                <p className="text-sm max-xsm:text-[12px]">archive</p>
              </div>
            </button>
          </div>
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark xl:flex">

            <div className="flex h-full flex-col border-l border-stroke dark:border-strokedark w-full">

              <div className="flex flex-col overflow-x-hidden no-scrollbar h-[560px] space-y-3.5 overflow-auto px-6 py-7.5">
                <p className="leading-none text-[12px] w-full text-center text-secondary my-4">Conversation starts from here.</p>
                {messages.map((message) => {
                  const fileName = message.content.text;
                  const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.flv', '.wmv', '.webm', '.m4v', '.mpeg', '.mpg'];
                  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.ico'];
                  const fileExtension = fileName?.substring(fileName.lastIndexOf('.')).toLowerCase() || '';
                  console.log('currentUser', currentUser);
                  if (currentUser?.id) {
                    if (message.userId === currentUser?.id) {
                      return (
                        <div className="flex gap-3 max-w-[70%]">
                          {!currentUser?.profileImage
                            ? (
                              <div className="flex items-center justify-center h-10 w-full max-w-10 overflow-hidden rounded-full bg-blue text-primary">
                                <h5>{buyer?.userName?.substring(0, 1).toUpperCase()}</h5>
                              </div>
                            )
                            : <Avatar src={currentUser.profileImage} size={10} />}
                          {message.content.imageId
                            ? (
                              <span className="underline max-w-full flex items-center gap-2 bg-transparent rounded-xl">
                                <a href={message.content.imageId} target="_blank" className="cursor-pointer max-w-full" rel="noreferrer">
                                  <div className="flex flex-col gap-2">
                                    {videoExtensions.includes(fileExtension)
                                        && (
                                        // eslint-disable-next-line jsx-a11y/media-has-caption
                                        <video className="rounded-lg ring-1 ring-white/10 max-h-54" controls>
                                          <source src={message.content.imageId} type="video/mp4" />
                                        </video>
                                        )}
                                    {imageExtensions.includes(fileExtension) && (
                                      <img
                                        className="rounded-lg ring-1 ring-white/10 max-h-54"
                                        src={message.content.imageId}
                                        alt="img"
                                      />
                                    )}
                                    <div className="flex gap-2 items-center text-white/90 max-w-full">
                                      <AttachmentIcon className="h-4 min-w-4" />
                                      <p className="text-ellipsis overflow-hidden whitespace-nowrap">{message.content.text}</p>
                                    </div>
                                  </div>
                                </a>
                              </span>
                            ) : (
                              <div className="max-w-full rounded-2xl rounded-tl-none bg-gray py-3 px-5 dark:bg-boxdark-2 text-sm">
                                <p className="break-words">{message.content.text}</p>
                              </div>
                            )}
                        </div>
                      );
                    }
                    return (
                      <div className="ml-auto flex justify-end max-w-[70%]">
                        {message.content.imageId
                          ? (
                            <span className="mr-0 underline max-w-full flex items-center gap-2 bg-transparent rounded-xl">
                              <a href={message.content.imageId} target="_blank" className="cursor-pointer max-w-full" rel="noreferrer">
                                <div className="flex flex-col gap-2">
                                  {videoExtensions.includes(fileExtension)
                                        && (
                                  // eslint-disable-next-line jsx-a11y/media-has-caption
                                        <video className="rounded-lg ring-1 ring-white/10 max-h-54" controls>
                                          <source src={message.content.imageId} type="video/mp4" />
                                        </video>
                                        )}
                                  {imageExtensions.includes(fileExtension) && (
                                    <img
                                      className="rounded-lg ring-1 ring-white/10 max-h-54"
                                      src={message.content.imageId}
                                      alt="img"
                                    />
                                  )}
                                  <div className="flex gap-2 items-center text-white/90 max-w-full">
                                    <AttachmentIcon className="h-4 min-w-4" />
                                    <p className="max-w-full text-ellipsis overflow-hidden whitespace-nowrap">{message.content.text}</p>
                                  </div>
                                </div>
                              </a>
                            </span>
                          ) : <div className="mb-2.5 max-w-full rounded-2xl rounded-br-none bg-primary py-3 px-5 text-sm"><p className="text-white">{message.content.text}</p></div>}
                      </div>
                    );
                  }
                  if (message.userId === dispute?.buyerUserId) {
                    return (
                      <div className="flex items-center gap-3 max-w-[70%]">
                        {!buyer?.profileImage
                          ? (
                            <div className="flex items-center justify-center h-10 w-full max-w-10 overflow-hidden rounded-full bg-blue text-primary">
                              <h5>{buyer?.userName?.substring(0, 1).toUpperCase()}</h5>
                            </div>
                          )
                          : <Avatar src={buyer.profileImage} size={6} />}
                        <div className="max-w-full text-sm">
                          {message.content.imageId
                            ? (
                              <span className="underline max-w-full flex items-center gap-2 bg-transparent rounded-xl">
                                <a href={message.content.imageId} target="_blank" className="cursor-pointer max-w-full" rel="noreferrer">
                                  <div className="flex flex-col gap-2">
                                    {videoExtensions.includes(fileExtension)
                                && (
                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video className="rounded-lg ring-1 ring-white/10 max-h-54" controls>
                                  <source src={message.content.imageId} type="video/mp4" />
                                </video>
                                )}
                                    {imageExtensions.includes(fileExtension) && (
                                    <img
                                      className="rounded-lg ring-1 ring-white/10 max-h-54"
                                      src={message.content.imageId}
                                      alt="img"
                                    />
                                    )}
                                    <div className="flex gap-2 items-center max-w-full">
                                      <AttachmentIcon className="h-4 min-w-4" />
                                      <p className="text-ellipsis overflow-hidden whitespace-nowrap">{message.content.text}</p>
                                    </div>
                                  </div>
                                </a>
                              </span>
                            ) : <p className="max-w-full text-ellipsis overflow-hidden whitespace-nowrap rounded-2xl rounded-tl-none bg-gray py-3 px-5 dark:bg-boxdark-2 ">{message.content.text}</p>}
                        </div>
                      </div>
                    );
                  }
                  if (message.userId === dispute?.sellerUserId) {
                    return (
                      <div className="ml-auto flex items-center gap-3 max-w-[70%]">
                        <div className="overflow-hidden">
                          {message.content.imageId
                            ? (
                              <span className="underline max-w-full flex items-center gap-2 bg-transparent rounded-xl justify-end">
                                <a href={message.content.imageId} target="_blank" className="cursor-pointer max-w-full" rel="noreferrer">
                                  <div className="flex flex-col gap-2 items-end">
                                    {videoExtensions.includes(fileExtension)
                                && (
                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video className="rounded-lg ring-1 ring-white/10 max-h-54" controls>
                                  <source src={message.content.imageId} type="video/mp4" />
                                </video>
                                )}
                                    {imageExtensions.includes(fileExtension) && (
                                    <img
                                      className="rounded-lg ring-1 ring-white/10 max-h-54"
                                      src={message.content.imageId}
                                      alt="img"
                                    />
                                    )}
                                    <div className="flex gap-2 items-center justify-end max-w-full">
                                      <AttachmentIcon className="h-4 min-w-4" />
                                      <p className="text-ellipsis overflow-hidden whitespace-nowrap">{message.content.text}</p>
                                    </div>
                                  </div>
                                </a>
                              </span>
                            )
                            : <p className="max-w-full text-white rounded-2xl rounded-br-none bg-primary py-3 px-5 text-sm">{message.content.text}</p>}
                        </div>
                        {!seller?.profileImage
                          ? (
                            <div className="flex items-center justify-center h-10 w-full max-w-10 overflow-hidden rounded-full bg-blue text-primary">
                              <h5>{seller?.userName?.substring(0, 1).toUpperCase()}</h5>
                            </div>
                          )
                          : <Avatar src={seller.profileImage} size={10} />}
                      </div>
                    );
                  }
                  return <div />;
                })}
              </div>
              {currentUser?.id && dispute?.disputeStatus === DisputeStatusTypes.Open && (
              <div className="sticky bottom-0 border-t border-stroke bg-white py-5 px-6 dark:border-strokedark dark:bg-boxdark">
                <div className="flex items-center justify-between space-x-4.5">
                  <div className="relative w-full">
                    <input
                      type="text"
                      placeholder="Type something here"
                      value={(inputValue instanceof File) ? inputValue.name : inputValue}
                      onChange={(event) => { setInputValue(event.target.value as string); }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleSendClick();
                          setInputValue('');
                        }
                      }}
                      disabled={!currentUser}
                      className="h-13 w-full rounded-md border border-stroke bg-gray pl-5 pr-19 text-black placeholder-body outline-none focus:border-primary dark:border-strokedark dark:bg-boxdark-2 dark:text-white"
                    />
                    <div className="absolute right-5 top-1/2 inline-flex -translate-y-1/2 items-center justify-end space-x-4">
                      <button
                        onClick={() => inputRef.current?.click()}
                        className="hover:text-primary p-0"
                        disabled={!currentUser}
                      >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current">
                          <path fillRule="evenodd" clipRule="evenodd" d="M11.835 1.79102C11.2378 1.79102 10.6651 2.02824 10.2428 2.45051L3.3503 9.34302C2.64657 10.0467 2.25122 11.0012 2.25122 11.9964C2.25122 12.9917 2.64657 13.9461 3.3503 14.6499C4.05403 15.3536 5.0085 15.7489 6.00372 15.7489C6.99895 15.7489 7.95341 15.3536 8.65714 14.6499L15.5496 7.75736C15.8425 7.46446 16.3174 7.46446 16.6103 7.75736C16.9032 8.05025 16.9032 8.52512 16.6103 8.81802L9.7178 15.7105C8.73277 16.6956 7.39677 17.2489 6.00372 17.2489C4.61067 17.2489 3.27468 16.6956 2.28964 15.7105C1.30461 14.7255 0.751221 13.3895 0.751221 11.9964C0.751221 10.6034 1.30461 9.26739 2.28964 8.28236L9.18214 1.38985C9.88572 0.686279 10.84 0.291016 11.835 0.291016C12.83 0.291016 13.7842 0.686279 14.4878 1.38985C15.1914 2.09343 15.5866 3.04768 15.5866 4.04268C15.5866 5.03769 15.1914 5.99194 14.4878 6.69552L7.5878 13.588C7.16569 14.0101 6.59318 14.2473 5.99622 14.2473C5.39926 14.2473 4.82676 14.0101 4.40464 13.588C3.98253 13.1659 3.74539 12.5934 3.74539 11.9964C3.74539 11.3995 3.98253 10.827 4.40464 10.4049L10.7725 4.04454C11.0655 3.75182 11.5404 3.7521 11.8331 4.04517C12.1258 4.33823 12.1256 4.81311 11.8325 5.10583L5.4653 11.4655C5.32469 11.6063 5.24539 11.7974 5.24539 11.9964C5.24539 12.1956 5.32449 12.3865 5.4653 12.5274C5.60611 12.6682 5.79709 12.7473 5.99622 12.7473C6.19535 12.7473 6.38633 12.6682 6.52714 12.5274L13.4271 5.63486C13.8492 5.21261 14.0866 4.63973 14.0866 4.04268C14.0866 3.4455 13.8494 2.87278 13.4271 2.45051C13.0049 2.02824 12.4321 1.79102 11.835 1.79102Z" />
                        </svg>
                        <input ref={inputRef} className="absolute bottom-0 opacity-0 w-full h-full -z-10 p-0 m-0" name="chatFile" type="file" accept="" onChange={handleImageChange} />
                      </button>
                      {/* <button className="hover:text-primary p-0" disabled={!currentUser}> */}
                      {/*   <svg width="19" height="18" viewBox="0 0 19 18" className="fill-current"> */}
                      {/*     <path fillRule="evenodd" clipRule="evenodd" d="M9.5 2.25C5.77208 2.25 2.75 5.27208 2.75 9C2.75 12.7279 5.77208 15.75 9.5 15.75C13.2279 15.75 16.25 12.7279 16.25 9C16.25 5.27208 13.2279 2.25 9.5 2.25ZM1.25 9C1.25 4.44365 4.94365 0.75 9.5 0.75C14.0564 0.75 17.75 4.44365 17.75 9C17.75 13.5564 14.0564 17.25 9.5 17.25C4.94365 17.25 1.25 13.5564 1.25 9Z" /> */}
                      {/*     <path fillRule="evenodd" clipRule="evenodd" d="M7.09769 10.0469C6.84856 9.71825 6.38037 9.6523 6.05004 9.90004C5.71867 10.1486 5.65152 10.6187 5.90004 10.95L6.50004 10.5C5.90004 10.95 5.90022 10.9503 5.90041 10.9505L5.9008 10.9511L5.90167 10.9522L5.90372 10.9549L5.90913 10.962L5.9251 10.9824C5.93803 10.9988 5.95555 11.0204 5.97757 11.0467C6.02155 11.0991 6.08379 11.17 6.16363 11.2533C6.32269 11.4193 6.55512 11.6379 6.85579 11.8566C7.45424 12.2918 8.3559 12.75 9.50004 12.75C10.6442 12.75 11.5458 12.2918 12.1443 11.8566C12.445 11.6379 12.6774 11.4193 12.8365 11.2533C12.9163 11.17 12.9785 11.0991 13.0225 11.0467C13.0445 11.0204 13.0621 10.9988 13.075 10.9824L13.091 10.962L13.0964 10.9549L13.0984 10.9522L13.0993 10.9511L13.0997 10.9505C13.0999 10.9503 13.1 10.95 12.5 10.5L13.1 10.95C13.3486 10.6187 13.2814 10.1486 12.95 9.90004C12.6197 9.6523 12.1515 9.71825 11.9024 10.0469L11.8989 10.0514C11.8945 10.057 11.886 10.0676 11.8736 10.0823C11.8487 10.112 11.8084 10.1582 11.7535 10.2155C11.643 10.3308 11.477 10.4872 11.262 10.6435C10.8292 10.9583 10.2309 11.25 9.50004 11.25C8.76919 11.25 8.17084 10.9583 7.73805 10.6435C7.52309 10.4872 7.35709 10.3308 7.24661 10.2155C7.19168 10.1582 7.15139 10.112 7.12653 10.0823C7.11412 10.0676 7.10563 10.057 7.10117 10.0514L7.09769 10.0469Z" /> */}
                      {/*     <path fillRule="evenodd" clipRule="evenodd" d="M6.5 6.75C6.5 6.33579 6.83579 6 7.25 6H7.2575C7.67171 6 8.0075 6.33579 8.0075 6.75C8.0075 7.16421 7.67171 7.5 7.2575 7.5H7.25C6.83579 7.5 6.5 7.16421 6.5 6.75Z" /> */}
                      {/*     <path fillRule="evenodd" clipRule="evenodd" d="M11 6.75C11 6.33579 11.3358 6 11.75 6H11.7575C12.1717 6 12.5075 6.33579 12.5075 6.75C12.5075 7.16421 12.1717 7.5 11.7575 7.5H11.75C11.3358 7.5 11 7.16421 11 6.75Z" /> */}
                      {/*   </svg> */}
                      {/* </button> */}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="flex h-13 w-full max-w-13 items-center justify-center rounded-md bg-primary text-white hover:bg-opacity-90 p-0"
                    onClick={() => {
                      handleSendClick();
                      setInputValue('');
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? <Loader /> : (
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 2L11 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              )}
              {' '}

            </div>
          </div>
        </div>
        {offer && (
        <OfferTradeDetails
          className="my-6 min-w-[300px]"
          escrowFee={dispute?.escrowFee}
          offer={offer}
          amount={dispute?.offeredAmount as number}
          isAdmin
        />
        )}
      </div>
    </div>
  );
};

export default Dispute;
