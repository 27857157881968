import React, { useContext, useEffect, useState } from 'react';
import { useCurrency } from '../../../stores/hooks';

import { AppElement, AppFC } from '../../../interfaces';
import { OfferTradeDetailsProps } from './types';
import { getCurrencyIcon, numberWithCommas } from '../../../utils';

import { Line } from '../../Atoms';

import infoIcon from '../../../assets/icons/info.svg';
import { PaymentMethodBox } from '../../Atoms/offerItems';
import { PaymentContext } from '../../../stores/contexts';

export const OfferTradeDetails: AppFC<OfferTradeDetailsProps> = ({
  offer,
  amount,
  escrowFee: defaultEscrowFee,
  isSeller,
  isBuyer,
  isAdmin,
  className,
}): AppElement => {
  const { getCurrencyGasFee, getCryptocurrencyRate } = useCurrency();

  const [gasFee, setGasFee] = useState(0);
  const [currencyRate, setCurrencyRate] = useState(0);

  const price = ((Number(currencyRate) * (100 + offer.margin)) / 100);

  const { paymentState: { methods } } = useContext(PaymentContext);

  const escrowFeePercent = methods.find(({ name }) => name === offer.paymentMethodTypes[0])?.escrowFeePercent as number || 0;
  const escrowFeeAmount = (defaultEscrowFee || defaultEscrowFee === 0) && defaultEscrowFee;

  useEffect(() => {
    Promise.all([
      getCurrencyGasFee(offer.offeredCurrency),
      getCryptocurrencyRate(offer.demandedCurrency, offer.offeredCurrency),
    ])
      .then((res) => {
        setGasFee(res[0]?.gasFee);
        setCurrencyRate(res[1].rate);
      });
  }, [offer]);

  return (
    <div className={`flex flex-col flex-1 max-w-[500px] w-full gap-14 ${className || ''}`}>
      <div className="flex flex-col gap-3">
        <h5>Unit Price</h5>
        <h3 className="flex items-center gap-2">
          <div className="h-7 [&>img]:h-full">{getCurrencyIcon(offer.offeredCurrency)}</div>
          {`1 ${offer.offeredCurrency} = $${numberWithCommas(price, '', 2)}`}
        </h3>
        <span className="text-secondary max-w-[320px]">
          {`${Math.abs(offer.margin)}% ${offer.margin > 0 ? 'above' : 'below'}  market (price set by the seller) Following the Binance ${offer.offeredCurrency}/${offer.demandedCurrency} trading pair.`}
        </span>
      </div>
      <div className="flex flex-col gap-5">
        <h5>Price Breakdown</h5>
        <div className="flex flex-col gap-2">
          {isBuyer && (
          <div className="flex justify-between">
            <span>You will pay</span>
            <span>
              {numberWithCommas(amount * price, offer?.demandedCurrency, 8)}
            </span>
          </div>
          )}
          {isSeller && (
          <>
            <div className="flex justify-between">
              <span>You will transfer</span>
              <span>
                {Number(amount) ? numberWithCommas(Number(amount) + gasFee + (escrowFeeAmount || ((escrowFeePercent * Number(amount)) / 100)), offer.offeredCurrency, 8) : numberWithCommas(0, offer.offeredCurrency)}
              </span>
            </div>
            <div className="flex justify-between">
              <span>Buyer will receive</span>
              <span>
                {numberWithCommas(amount, offer?.offeredCurrency, 8)}
              </span>
            </div>
            {' '}
            <div className="flex justify-between">
              <span>Cryptolocally fee</span>
              <span>
                {Number(amount) ? numberWithCommas(escrowFeeAmount || ((amount * escrowFeePercent) / 100), offer.offeredCurrency, 8) : `${escrowFeePercent}%`}
              </span>
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-1">
                <span>Network fee estimate</span>
                <img src={infoIcon} alt="" />
              </div>
              <span>
                ~
                {' '}
                {numberWithCommas(gasFee, offer.offeredCurrency, 10)}
              </span>
            </div>
          </>
          )}
          {isAdmin && (
            <>
              <div className="flex justify-between">
                <span>Buyer will transfer</span>
                <span>
                  {amount ? numberWithCommas(Number(amount) + gasFee + (escrowFeeAmount || ((Number(amount) * escrowFeePercent) / 100)), offer.offeredCurrency, 8) : numberWithCommas(0, offer.offeredCurrency)}
                </span>
              </div>
              <div className="flex justify-between">
                <span>Seller will receive</span>
                <span>
                  {numberWithCommas(amount, offer?.offeredCurrency, 8)}
                </span>
              </div>
              <div className="flex justify-between">
                <span>Cryptolocally fee</span>
                <span>
                  {amount ? numberWithCommas(escrowFeeAmount || ((Number(amount) * escrowFeePercent) / 100), offer.offeredCurrency, 8) : `${escrowFeePercent}%`}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-1">
                  <span>Network fee estimate</span>
                  <img src={infoIcon} alt="" />
                </div>
                <span>
                  ~
                  {' '}
                  {numberWithCommas(gasFee, offer.offeredCurrency, 10)}
                </span>
              </div>
            </>
          )}
          <Line />
          {!isAdmin && (
          <div className="flex justify-between [&>span]:font-semibold">
            <span>You will get</span>
            {isBuyer && (
            <span>
              {amount ? numberWithCommas(amount, offer?.offeredCurrency, 8) : numberWithCommas(0, offer.offeredCurrency)}
            </span>
            )}
            {isSeller && (
            <span>
              {amount ? numberWithCommas(amount * price, offer.demandedCurrency, 8) : numberWithCommas(0, offer.demandedCurrency)}
            </span>
            )}
          </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {!isAdmin ? (
          <h5>
            I want to
            {isSeller ? ' buy' : ' sell'}
          </h5>
        )
          : (
            <h5>
              Payment method
            </h5>
          )}
        <PaymentMethodBox
          methods={offer.paymentMethodTypes}
        />
      </div>
      <div className="flex flex-col gap-5">
        <h5>Terms of Trade</h5>
        <span className="px-4 py-3 border border-listItem/40 rounded-lg text-secondary">
          {offer.description || 'No terms'}
        </span>
      </div>
    </div>
  );
};
